
import Error from "@/components/Error.vue";
import PrettyBar from "@/components/PrettyBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import VDButton from "@/components/VDButton.vue";
import useError from "@/composables/useError";
import useToastService from "@/composables/useToastService";
import { OrganizationType, RoleType, useGetRoleQuery } from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import gql from "graphql-tag";
import { computed, defineComponent, reactive, ref, toRef, watch } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import useCreateRole from "./mutations/useCreateRole";
import useDeleteRole from "./mutations/useDeleteRole";
import useUpdateRole from "./mutations/useUpdateRole";
import OrganizationsPicker from "@/components/pickers/OrganizationsPicker.vue";
import { VDPopupListboxClass } from "@/components/pickers/VDPopupListbox";
import useAddRoleAssignee from "./mutations/useAddRoleAssignee";
import useRemoveRoleAssignee from "./mutations/useRemoveRoleAssignee";
import useConfirmService from "@/composables/useConfirmService";

gql`
  query getRole($id: ID) {
    role(roleId: $id) {
      ...RoleParts
      application {
        applicationId
        name
      }
      assignees {
        organizationId
        name
      }
      users {
        items {
          organization {
            name
          }
          email
          userId
          firstname
          lastname
        }
      }
    }
  }
`;

export default defineComponent({
  components: {
    Sidebar,
    PrettyBar,
    Error,
    VDButton,
    OrganizationsPicker,
  },
  name: "Role",
  props: {
    applicationId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // Services
    const toastService = useToastService();
    const router = useRouter();
    const confirmService = useConfirmService("sidebar");

    // VDom refs
    const organizationPicker = ref<VDPopupListboxClass>();

    // Component refs
    const isNew = computed(() => props.id == "new");
    const originalRole = ref<RoleType>({});
    const role = reactive<RoleType>({});
    const hasChanges = computed(
      () =>
        !(
          originalRole.value.name == role.name &&
          originalRole.value.description == role.description &&
          originalRole.value.attribute == role.attribute
        )
    );
    const hasConfirmedLeave = ref(false);

    const v$ = useVuelidate(useValidations(), {
      name: toRef(role, "name"),
      attribute: toRef(role, "attribute"),
    });

    const updateMutation = useUpdateRole();
    const createMutation = useCreateRole();
    const deleteMutation = useDeleteRole();
    const addRoleAssigneMutation = useAddRoleAssignee();
    const removeRoleAssigneMutation = useRemoveRoleAssignee();

    const { result, loading, error } = useGetRoleQuery(props, () => ({
      enabled: props.id != "new",
      fetchPolicy: "network-only",
    }));

    function onPrimaryButton() {
      v$.value.$touch();
      if (!v$.value.$error) {
        isNew.value
          ? createMutation.call(props.applicationId, role)
          : updateMutation.call(props.applicationId, role);
      }
    }

    function onDeleteButton() {
      deleteMutation.call(props.applicationId, props.id);
    }

    function onAddedOrganization(organization: OrganizationType) {
      function add() {
        role.assignees = [...(role.assignees ?? []), organization];
      }

      if (!isNew.value) {
        addRoleAssigneMutation
          .call(organization.organizationId ?? "", props.id)
          .then((value) => {
            if (value != null) add();
          });
      } else {
        add();
      }
    }

    function onRemoveOrganization(organization: OrganizationType) {
      function remove() {
        role.assignees =
          role.assignees?.filter(
            (element) => element?.organizationId != organization.organizationId
          ) ?? [];
      }

      if (!isNew.value) {
        removeRoleAssigneMutation
          .call(organization.organizationId ?? "", props.id)
          .then((value) => {
            if (value != null) remove();
          });
      } else {
        remove();
      }
    }

    watch(
      () => useResult(result).value,
      (newValue) => {
        originalRole.value = newValue ?? {};
        Object.assign(role, newValue);
      },
      { immediate: true }
    );

    createMutation.onDone(({ data }) => {
      toastService.show({
        title: "Rolle oprettet",
        message: `Den har fået ID: ${data?.createRole?.roleId}`,
        severity: "success",
      });
      hasConfirmedLeave.value = true;
      router.back();
    });

    updateMutation.onDone(() => {
      toastService.show({
        title: "Rolle ændret",
        message: "Dine ændringer er blevet gemt",
        severity: "success",
      });
      hasConfirmedLeave.value = true;
      router.back();
    });

    deleteMutation.onDone(() => {
      toastService.show({
        title: "Rolle slettet",
        message: "Rollen er blevet slettet",
        severity: "success",
      });
      hasConfirmedLeave.value = true;
      router.back();
    });

    onBeforeRouteLeave(() => {
      if (!hasConfirmedLeave.value && hasChanges.value && !isNew.value) {
        return new Promise((resolve) =>
          confirmService.show(
            null,
            "Du har foretaget ændringer der ikke er gemt, vil du stadig gå tilbage?",
            "Ja, gå tilbage",
            "Afbryd",
            () => {
              hasConfirmedLeave.value = true;
              resolve(true);
            },
            () => resolve(false)
          )
        );
      } else {
        return true;
      }
    });

    return {
      v$,
      isNew,
      organizationPicker,
      hasChanges,
      role,
      loading,
      error: useError(error),
      primaryButtonLoading: computed(
        () => createMutation.loading.value || updateMutation.loading.value
      ),
      deleteMutation,
      addRemoveLoading: computed(
        () =>
          addRoleAssigneMutation.loading.value ||
          removeRoleAssigneMutation.loading.value
      ),
      onPrimaryButton,
      onDeleteButton,
      onAddedOrganization,
      onRemoveOrganization,
      deleteRole: (event: Event) => {
        confirmService.show(
          event,
          "Er du sikker på at du vil slette rollen? Denne handling kan ikke fortrydes.",
          "Ja, slet",
          "Afbryd",
          () => deleteMutation.call(props.applicationId, props.id)
        );
      },
    };
  },
});

function useValidations() {
  return {
    name: { required },
    attribute: { required },
  };
}
