import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VDPopupListbox = _resolveComponent("VDPopupListbox")!

  return (_openBlock(), _createBlock(_component_VDPopupListbox, {
    ref: "listbox",
    dataKey: ['organizationId'],
    disabled: _ctx.disabled,
    onOptionAdded: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('addedOrganization', $event))),
    disabledOptions: _ctx.addedOrganizations,
    filterFields: ['name', 'organizationId'],
    isLoading: _ctx.loading,
    options: _ctx.organizations,
    appendTo: "#sidebar"
  }, {
    default: _withCtx(({ option }) => [
      _createVNode("span", null, _toDisplayString(option.firstname) + " " + _toDisplayString(option.name), 1)
    ]),
    _: 1
  }, 8, ["disabled", "disabledOptions", "isLoading", "options"]))
}