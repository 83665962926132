
import { VDError } from "@/models/VDError";
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
} from "vue";
import { useRouter } from "vue-router";
import Error from "./Error.vue";
import Spinner from "./Spinner.vue";

export default defineComponent({
  components: { Spinner, Error },
  inheritAttrs: false,
  name: "Sidebar",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: "Vent venligst ...",
    },
    error: {
      type: Object as PropType<VDError | null>,
      default: null,
    },
    padding: {
      type: String,
      default: "p-5",
    },
  },
  setup() {
    const router = useRouter();
    const show = ref(false);
    let scrollPosition = 0;

    const onKey = (e: KeyboardEvent) => {
      if (e.code == "Escape") router.back();
    };

    function disableScrolling() {
      scrollPosition = window.pageYOffset;
      const $body = document.querySelector("body");
      if ($body) {
        $body.style.overflow = "hidden";
        $body.style.position = "fixed";
        $body.style.top = `-${scrollPosition}px`;
        $body.style.width = "100%";
      }
    }

    function enableScrolling() {
      const $body = document.querySelector("body");
      if ($body) {
        $body.style.removeProperty("overflow");
        $body.style.removeProperty("position");
        $body.style.removeProperty("top");
        $body.style.removeProperty("width");
        window.scrollTo(0, scrollPosition);
      }
    }

    onMounted(() => {
      window.addEventListener("keyup", onKey, false);

      setTimeout(() => {
        show.value = true;
      }, 80);
      disableScrolling();
    });

    onBeforeUnmount(() => {
      window.removeEventListener("keyup", onKey);
      enableScrolling();
    });

    return {
      performRoute: () => {
        // router.back();
      },
      show,
    };
  },
});
